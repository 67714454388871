import React from 'react'
import loadable from '@loadable/component'
import { setPageError } from 'client/store/ui/actions'
import { Redirect } from 'react-router'
import CircleSpinner from 'client/components/misc/CircleSpinner'
import Error from 'client/pages/Error'

const loadingStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '80vh'
}

const loadableOpts = {
  fallback: (
    <div style={loadingStyles}>
      <CircleSpinner />
    </div>
  )
}

const Home = loadable(() => import(/* webpackChunkName: "Home" */ 'client/pages/HomeApp'), loadableOpts)
const Report = loadable(() => import(/* webpackChunkName: "Report" */ 'client/pages/ReportApp'), loadableOpts)
const UserMe = loadable(() => import(/* webpackChunkName: "UserMe" */ 'client/pages/UserMe'), loadableOpts)
const UserProfileHub = loadable(
  () => import(/* webpackChunkName: "UserProfileHub" */ 'client/pages/UserProfileHub'),
  loadableOpts
)
const SearchApp = loadable(() => import(/* webpackChunkName: "Search" */ 'client/pages/SearchApp'), loadableOpts)
const SearchBuilderApp = loadable(
  () => import(/* webpackChunkName: "SearchBuilder" */ 'client/pages/SearchBuilderApp'),
  loadableOpts
)
const Classify = loadable(() => import(/* webpackChunkName: "Classify" */ 'client/pages/Classify'), loadableOpts)
const PrivacyPolicy = loadable(
  () => import(/* webpackChunkName: "PrivacyPolicy" */ 'client/pages/PrivacyPolicy'),
  loadableOpts
)
const TermsOfService = loadable(
  () => import(/* webpackChunkName: "TermsOfService" */ 'client/pages/TermsOfService'),
  loadableOpts
)
const Copyright = loadable(() => import(/* webpackChunkName: "Copyright" */ 'client/pages/Copyright'), loadableOpts)
const ApiTerms = loadable(() => import(/* webpackChunkName: "ApiTerms" */ 'client/pages/ApiTerms'), loadableOpts)
const AccessibilityStatement = loadable(
  () => import(/* webpackChunkName: "AccessibilityStatement" */ 'client/pages/AccessibilityStatement'),
  loadableOpts
)
const CommunityGuidelines = loadable(
  () => import(/* webpackChunkName: "CommunityGuidelines" */ 'client/pages/CommunityGuidelines'),
  loadableOpts
)
const ExtensionInstall = loadable(
  () => import(/* webpackChunkName: "ExtensionInstall" */ 'client/pages/ExtensionInstall'),
  loadableOpts
)
const BadgeTester = loadable(
  () => import(/* webpackChunkName: "BadgeTester" */ 'client/pages/BadgeTester'),
  loadableOpts
)
const BadgeApp = loadable(() => import(/* webpackChunkName: "BadgeApp" */ 'client/pages/BadgeApp'), loadableOpts)
const BadgeTesterInternal = loadable(
  () => import(/* webpackChunkName: "BadgeTesterInternal" */ 'client/pages/BadgeTesterInternal'),
  loadableOpts
)
const Jobs = loadable(() => import(/* webpackChunkName: "Jobs" */ 'client/pages/Jobs/Jobs'), loadableOpts)
const VerifyEmail = loadable(
  () => import(/* webpackChunkName: "VerifyEmail" */ 'client/pages/VerifyEmailApp'),
  loadableOpts
)
const Visualizer = loadable(() => import(/* webpackChunkName: "Visualizer" */ 'client/pages/Visualizer'), loadableOpts)
const ReferenceCheck = loadable(
  () => import(/* webpackChunkName: "ReferenceCheck" */ 'client/pages/ReferenceCheck'),
  loadableOpts
)
const AffiliationDashboard = loadable(
  () => import(/* webpackChunkName: "AffiliationDashboard" */ 'client/pages/AffiliationDashboard'),
  loadableOpts
)
const InstitutionDashboard = loadable(
  () => import(/* webpackChunkName: "InstitutionDashboard" */ 'client/pages/InstitutionDashboard'),
  loadableOpts
)
const JournalDashboard = loadable(
  () => import(/* webpackChunkName: "JournalDashboard" */ 'client/pages/JournalDashboard'),
  loadableOpts
)
const CustomDashboardApp = loadable(
  () => import(/* webpackChunkName: "CustomDashboardApp" */ 'client/pages/CustomDashboardApp'),
  loadableOpts
)
const JournalsTableApp = loadable(
  () => import(/* webpackChunkName: "JournalsTableApp" */ 'client/pages/JournalsTableApp'),
  loadableOpts
)
const AffiliationsTableApp = loadable(
  () => import(/* webpackChunkName: "AffiliationsTableApp" */ 'client/pages/AffiliationsTableApp'),
  loadableOpts
)
const InstitutionsTableApp = loadable(
  () => import(/* webpackChunkName: "InstitutionsTableApp" */ 'client/pages/InstitutionsTableApp'),
  loadableOpts
)
const Pricing = loadable(() => import(/* webpackChunkName: "Pricing" */ 'client/pages/Pricing'), loadableOpts)
const RequestADemo = loadable(
  () => import(/* webpackChunkName: "RequestADemo" */ 'client/pages/RequestADemo'),
  loadableOpts
)
const ArticleGalaxyLanding = loadable(
  () => import(/* webpackChunkName: "ArticleGalaxyLanding" */ 'client/pages/ArticleGalaxyLanding'),
  loadableOpts
)
const SciteFreeSummerForAG = loadable(
  () => import(/* webpackChunkName: "SciteFreeSummerForAG" */ 'client/pages/SciteFreeSummerForAG'),
  loadableOpts
)
const Admin = loadable(() => import(/* webpackChunkName: "Admin" */ 'client/pages/Admin'), loadableOpts)
const CreateDashboardApp = loadable(
  () => import(/* webpackChunkName: "CreateDashboardApp" */ 'client/pages/CreateDashboardApp'),
  loadableOpts
)
const PartnersPage = loadable(
  () => import(/* webpackChunkName: "PartnersPage" */ 'client/pages/PartnersPage'),
  loadableOpts
)
const EndorsementsPage = loadable(
  () => import(/* webpackChunkName: "EndorsementsPage" */ 'client/pages/EndorsementsPage'),
  loadableOpts
)
const AuthorProfile = loadable(
  () => import(/* webpackChunkName: "AuthorProfile" */ 'client/pages/AuthorProfile'),
  loadableOpts
)
const BlogApp = loadable(() => import(/* webpackChunkName: "BlogApp" */ 'client/pages/blog/BlogApp'), loadableOpts)
const BlogIndex = loadable(
  () => import(/* webpackChunkName: "BlogIndex" */ 'client/pages/blog/BlogIndex'),
  loadableOpts
)
const EmbedCitationSearch = loadable(
  () => import(/* webpackChunkName: "EmbedCitationSearch" */ 'client/pages/EmbedCitationSearch'),
  loadableOpts
)
const AffiliatesPage = loadable(
  () => import(/* webpackChunkName: "AffiliatesPage" */ 'client/pages/AffiliatesPage'),
  loadableOpts
)
const AuthorMarketingPage = loadable(
  () => import(/* webpackChunkName: "AuthorMarketingPage" */ 'client/pages/AuthorMarketingPage'),
  loadableOpts
)
const NewsAndPressPage = loadable(
  () => import(/* webpackChunkName: "NewsAndPressPage" */ '../../client/pages/NewsAndPressPage'),
  loadableOpts
)
const Usage = loadable(() => import(/* webpackChunkName: "Usage" */ '../../client/pages/Usage'), loadableOpts)
const CitationsWrapped = loadable(
  () => import(/* webpackChunkName: "CitationsWrapped" */ 'client/pages/CitationsWrapped'),
  loadableOpts
)
const CitationsWrappedLanding = loadable(
  () => import(/* webpackChunkName: "CitationsWrappedLanding" */ 'client/pages/CitationsWrappedLanding'),
  loadableOpts
)
const OrganizationLicenseHub = loadable(
  () => import(/* webpackChunkName: "OrganizationLicenseHub" */ 'client/pages/OrganizationLicenseHub'),
  loadableOpts
)
const OrganizationGettingStarted = loadable(
  () =>
    import(
      /* webpackChunkName: "OrganizationLicenseGettingStartedPage" */ 'client/pages/OrganizationLicenseGettingStartedPage'
    ),
  loadableOpts
)
const DataAndServices = loadable(
  () => import(/* webpackChunkName: "DataAndServices" */ 'client/pages/DataAndServicesPage'),
  loadableOpts
)
const AnalyzeSearch = loadable(
  () => import(/* webpackChunkName: "AnalyzeSearch" */ 'client/pages/AnalyzeSearch'),
  loadableOpts
)
const AssistantSharedApp = loadable(
  () => import(/* webpackChunkName: "AssistantSharedApp" */ 'client/pages/AssistantSharedApp'),
  loadableOpts
)
const AssistantApp = loadable(
  () => import(/* webpackChunkName: "AssistantApp" */ 'client/pages/AssistantApp'),
  loadableOpts
)
const SearchLanding = loadable(
  () => import(/* webpackChunkName: "SearchLanding" */ 'client/pages/SearchLanding'),
  loadableOpts
)
const ContactSales = loadable(
  () => import(/* webpackChunkName: "ContactSales" */ 'client/pages/ContactSales'),
  loadableOpts
)
const EnterpriseSignIn = loadable(
  () => import(/* webpackChunkName: "EnterpriseSignIn" */ 'client/pages/EnterpriseSignIn'),
  loadableOpts
)

const Rankings = loadable(
  () => import(/* webpackChunkName: "Rankings" */ 'client/pages/Rankings'),
  loadableOpts
)

const FourOhFour = () => <Error status={404} />

FourOhFour.getInitialActions = () => [setPageError({ status: 404 })]

export const routes = [
  {
    path: '/',
    component: Home,
    exact: true
  },
  {
    path: '/home',
    component: Home
  },
  {
    path: '/verify-email',
    component: VerifyEmail
  },
  {
    path: '/reports/:doi+',
    component: Report
  },
  {
    path: '/users/me',
    component: UserMe
  },
  {
    path: '/users/:slug',
    component: UserProfileHub
  },
  {
    path: '/search/analyze',
    component: AnalyzeSearch
  },
  {
    path: '/search/citations',
    component: ({ location }) => <Redirect to={location.search ? `/search${location.search}&mode=all` : '/search'} />
  },
  {
    path: '/search/builder',
    component: SearchBuilderApp
  },
  {
    path: '/search/landing',
    component: SearchLanding
  },
  {
    path: '/search',
    component: SearchApp
  },
  {
    path: '/classify',
    component: Classify
  },
  {
    path: '/policy',
    component: PrivacyPolicy
  },
  {
    path: '/terms',
    component: TermsOfService
  },
  {
    path: '/apiterms',
    component: ApiTerms
  },
  {
    path: '/accessibility-statement',
    component: AccessibilityStatement
  },
  {
    path: '/copyright',
    component: Copyright
  },
  {
    path: '/guidelines',
    component: CommunityGuidelines
  },
  {
    path: '/extension-install',
    component: ExtensionInstall
  },
  {
    path: '/author-marketing',
    component: AuthorMarketingPage
  },
  {
    path: '/badge-tester',
    component: BadgeTester
  },
  {
    path: '/badge-tester-internal',
    component: BadgeTesterInternal
  },
  {
    path: '/badge',
    component: BadgeApp
  },
  {
    path: '/embed-citation-search',
    component: EmbedCitationSearch
  },
  {
    path: '/jobs',
    component: Jobs
  },
  {
    path: '/visualizations/authorprofiles/:userSlug',
    component: Visualizer
  },
  {
    path: '/visualizations/:set+',
    component: Visualizer
  },
  {
    path: '/reference-check/:taskid',
    component: ReferenceCheck
  },
  {
    path: '/create-dashboard',
    component: CreateDashboardApp
  },
  {
    path: '/dashboard/:slug+',
    component: CustomDashboardApp
  },
  {
    path: '/journals/:slug+',
    component: JournalDashboard
  },
  {
    path: '/institutions/:slug+',
    component: InstitutionDashboard
  },
  {
    path: '/journals',
    component: JournalsTableApp
  },
  {
    path: '/affiliations/:slug+',
    component: AffiliationDashboard
  },
  {
    path: '/affiliations',
    component: AffiliationsTableApp
  },
  {
    path: '/institutions',
    component: InstitutionsTableApp
  },
  {
    path: '/pricing',
    component: Pricing
  },
  {
    path: '/request-a-demo',
    component: RequestADemo
  },
  {
    path: '/article-galaxy',
    component: ArticleGalaxyLanding
  },
  {
    path: '/scite-for-article-galaxy',
    component: SciteFreeSummerForAG
  },
  {
    path: '/checkout',
    component: Pricing // fallback to pricing page for now
  },
  {
    path: '/admin',
    component: Admin
  },
  {
    path: '/partners',
    component: PartnersPage
  },
  {
    path: '/endorsements',
    component: EndorsementsPage
  },
  {
    path: '/authors/:slug',
    component: AuthorProfile
  },
  {
    path: '/blog',
    component: BlogIndex,
    exact: true
  },
  {
    path: '/blog/:slug',
    component: BlogApp
  },
  {
    path: '/affiliate',
    component: AffiliatesPage
  },
  {
    path: '/news-and-press',
    component: NewsAndPressPage
  },
  {
    path: '/usage/:slug',
    component: Usage
  },
  {
    path: '/wrapped',
    component: () => <Redirect to='/scite-digest' />,
    exact: true
  },
  {
    path: '/wrapped/:slug',
    component: ({
      match: {
        params: { slug }
      }
    }) => <Redirect to={`/scite-digest/${slug}`} />
  },
  {
    path: '/scite-digest',
    component: CitationsWrappedLanding,
    exact: true
  },
  {
    path: '/scite-digest/:slug',
    component: CitationsWrapped
  },
  {
    path: '/manage-licenses',
    component: OrganizationLicenseHub
  },
  {
    path: '/organization-license-getting-started',
    component: OrganizationGettingStarted
  },
  {
    path: '/getting-started',
    component: OrganizationGettingStarted
  },
  {
    path: '/data-and-services',
    component: DataAndServices
  },
  {
    path: '/assistant/shared/:token',
    component: AssistantSharedApp
  },
  {
    path: '/assistant/:slug',
    component: AssistantApp
  },
  {
    path: '/assistant',
    component: AssistantApp
  },
  {
    path: '/contact-sales',
    component: ContactSales
  },
  {
    path: '/enterprise/:organizationLicenseSlug',
    component: EnterpriseSignIn
  },
  {
    path: '/rankings',
    component: Rankings
  },
  {
    path: '*',
    component: FourOhFour
  }
]

export default routes
