// Animated transitions disabled due to https://github.com/react-spring/react-spring/issues/435

import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
// import { Transition, config } from 'react-spring'
import Footer from 'client/components/footer/Footer'
import Error from 'client/pages/Error'
import routes from './routes'
import MaintenanceApp from '../../client/pages/MaintenanceApp'

//
// MILO:
//       Main app render function.
//
//       Renders either a route or an error
//
export const Main = ({ location, pageError, sickoMode }) => {
  if (CONFIG.maintenance) {
    return <MaintenanceApp />
  }

  if (pageError) {
    return <Error {...pageError} />
  }

  const noFooter = (
    location.pathname.includes('/users') ||
    location.pathname.includes('/wrapped') ||
    location.pathname.includes('/scite-digest') ||
    location.pathname.includes('/assistant') ||
    location.pathname.includes('/rankings')
  )

  return (
    <>
      <Switch location={location}>
        {routes.map((route, index) => <Route key={index} {...route} />)}
      </Switch>
      {!noFooter && <Footer />}
    </>
  )
}

const mapStateToProps = ({ ui }) => ({
  pageError: ui.pageError
})
export default compose(
  connect(mapStateToProps),
  withRouter
)(Main)
